export function exportExcel(data, filename) {
  let url = window.URL.createObjectURL(new Blob([data]));
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
}

export function exportExcel1(data, filename) {
  let url = window.URL.createObjectURL(new Blob([data]));
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}


export function ChangePicker(date, dateString) {
  // // 会传过来2个参数，都是'2024-09'这样的时间
  // console.log(54,date)
  // console.log(541,dateString)

  // let date1 = new Date('2024-09-01T00:00:00Z'); // UTC 时间

  let formatter = new Intl.DateTimeFormat('zh-CN', {
    timeZone: 'Asia/Shanghai',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit'
  });

  //
  let year = date.toString().split("-")[0]
  let month = date.toString().split("-")[1]
  let firstDay = new Date(parseInt(year), parseInt(month)-1, 1); // 注意：月份是从0开始计数的，所以9月是8
  let lastDay = new Date(parseInt(year), parseInt(month), 1); // 月份为9表示10月，但设置为0则回退到上个月的最后一日

  let first_day1 =  formatter.format(firstDay).replace("/","-").replace("/","-") ;
  let last_day1 =  formatter.format(lastDay).replace("/","-").replace("/","-") ;
  return {first_day1,last_day1}

}
